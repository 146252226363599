<template>
  <div id="app">
    <h1 style="text-align: center">Here are the posts we have 😊</h1>
    <el-row>
      <el-col :span="10" :offset="7">
        <el-card
          v-for="post in posts"
          :key="post.title"
          class="box-card"
          shadow="hover"
          style="margin: 20px"
        >
          <div slot="header" class="clearfix">
            <span>{{ post.title }}</span>
            <div style="float: right">
              <i class="el-icon-user-solid" /> {{ post.username }}
            </div>
          </div>
          {{ post.content }}
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "app",

  data() {
    return {
      posts: [],
    };
  },

  created() {
    axios.get("https://ga.zcz.workers.dev/posts").then((response) => {
      this.posts = response.data.filter((x) => {
        return "title" in x && "content" in x && "username" in x;
      });
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
